import { api } from '@/services/serviceApi.js'

const requestDataMixin = {
  data () {
    return {
      loading: false
    }
  },
  methods: {
    async requestData (type, url, params, data, loading) {
      if (loading) {
        this[loading] = true
      }

      const processedParams = new URLSearchParams()

      if (params) {
        for (const param in params) {
          if (Array.isArray(params[param])) {
            params[param].forEach(item => {
              processedParams.append(param, item)
            })
          } else {
            if (params[param] !== undefined) {
              processedParams.append(param, params[param])
            }
          }
        }
      }

      const request = await api[type](url, processedParams, data)

      if (loading) {
        this[loading] = false
      }

      return request
    }
  }
}

export default requestDataMixin
