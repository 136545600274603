import { createStore } from 'vuex'

const appTheme = {
  state: {
    themeDarkMode: false
  },
  mutations: {
    changeThemeDarkMode (state, val) {
      state.themeDarkMode = val
    }
  }
}

const loginMode = {
  state: {
    loggedInWithGoogle: false,
    userDetails: null
  },
  mutations: {
    changeLoginMode (state, val) {
      state.details = val
      state.loggedInWithGoogle = val.loggedIn
      // console.log('state change: ', state.details)
    }
  }
}

const ruleVersionDetails = {
  state: {
    configuration: null
  },
  mutations: {
    setRuleVersionConfiguration (state, data) {
      state.configuration = data
    }
  }
}

export default createStore({
  modules: {
    appTheme,
    loginMode,
    ruleVersionDetails
  }
})
