<template>
  <div
    class="login-page h-screen flex items-center justify-center p-4 bg-cover"
  >
    <div
      class="
        flex flex-col
        items-center
        w-full
        max-w-sm
        h-64
        px-8
        box-border
        bg-white
        border
        shadow-2xl
        rounded-md
      "
    >
      <img class="h-16 mt-8 my-4" src="../assets/icons/icon-lock.svg" />
      <h1 class="text-2xl mb-6">Randstad Live</h1>
      <CustomButton @clicked="login"> Log in with a Randstad account</CustomButton>
    </div>
  </div>
</template>

<script>
import CustomButton from "@/components/common/custom/CustomButton.vue";
import appSettings from "@/configs/appSettings.js";
import { useAuth0 } from "@/services/auth0Plugin";

export default {
  name: "LoginView",
  components: { CustomButton },
  data() {
    return {
      auth0: null,
    };
  },
  created() {
    if (appSettings.auth0Active === "true") {
      this.auth0 = useAuth0();
    }
  },
  methods: {
    login() {
      if (appSettings.auth0Active === "true") {
        if (!this.auth0) {
          return;
        }
        console.log("Setting user login preference as auth0")
        localStorage.setItem('rtv-logged-with-google', "false")

        this.auth0.login();
      } else {
        console.log('Passed login')
        this.$router.push("/");
      }
    },
  },
};
</script>
