<template>
  <div></div>
</template>

<script>
import appSettings from "@/configs/appSettings.js";
import { onMounted } from "vue";
import requestDataMixin from "@/mixins/requestDataMixin.js";
import endpoints from "@/configs/endpoints";
import moment from "moment";

export default {
  name: "DataFetcher",
  props: {
    auth0: Object,
  },
  setup() {},
  data() {
    return {
      loading: false,
      minuteShift: 30,
      intervalFetch: 1000,
      timeToLiveSeconds: 10 * 60,
      firstLoadPrefetchMinutes: 5,
      bufferSize: 5,
      alreadyHave: [],
      streams: ["bluex", "rce-rce-prd"],
      buffer: {},
      firstLoadDone: false,
    };
  },
  mixins: [requestDataMixin],
  mounted: function () {
    this.receiveData();
  },
  beforeUnmount() {
    clearInterval(this.intervalid1);
  },
  methods: {
    getNewDataPoints(response) {
      // this method parses the response of the server and adds new data points to the buffer
      for (const stream in response) {
        for (const date in response[stream]) {
          for (const second in response[stream][date]) {
            const dateFull = `${date}${second.substr(1, 2)}+00:00`;
            const key = `${stream}-${date}-${second}`;

            // first, lets decode the timestamp
            const fullDate = moment(dateFull, "[D]YYYYMMDD[T]hhmmssZ");
            // when do we need to display it?
            const timeToDisplay = fullDate
              .clone()
              .add(this.minuteShift, "minute");
            const timeToLive = timeToDisplay
              .clone()
              .add(this.timeToLiveSeconds, "second");

            // now, do we have both job and profile, or job only?

            const event = {
              ts: timeToDisplay,
              ttl: timeToLive,
              job: response[stream][date][second].job,
              profile: response[stream][date][second].person,
              added: false,
            };

            if (response[stream][date][second].job.type) {
              // console.log(response[stream][date][second].job);
              event.job = response[stream][date][second].job.loc;
              event.jobCountry = response[stream][date][second].job.jC;
              event.jobCity = response[stream][date][second].job.jCt;
              event.personCountry = response[stream][date][second].job.pC;
              event.jobDescription = response[stream][date][second].job.dsc;
            } else {
              event.job = response[stream][date][second].job;
            }

            if (
              response[stream][date][second].person &&
              response[stream][date][second].person.type
            ) {
              event.person = response[stream][date][second].person.loc;
              event.personCountry = response[stream][date][second].person.pC;
              event.personCity = response[stream][date][second].person.pCt;
            } else {
              event.person = null;
            }

            if (!this.buffer[key]) this.buffer[key] = event;
          }
        }
      }
      // console.log(this.buffer);
    },
    updateBuffer(data) {
      // first, remove any datapoints that are before our current present (start + timeToLive)
      const shiftedNow = moment().utc().subtract(this.minuteShift, "minute");
      const oldestAccepted = shiftedNow
        .clone()
        .subtract(this.timeToLiveSeconds, "second");
      this.getNewDataPoints(data);
      // console.log("Would remove data points before", oldestAccepted.format());
      this.$emit("new-data", this.buffer);
    },
    async pullData(streams, sliceSet) {
      const sliceSetStr = sliceSet.join(",");

      // console.log(await this.auth0.getJWTToken());

      const [error, data] = await this.requestData(
        "get",
        endpoints.stream,
        {
          streams: streams.join(","),
          datetimes: sliceSet.join(","),
        },
        "loading"
      );

      if (!error) {
        for (const slice of sliceSet) {
          if (!this.alreadyHave.includes(slice)) this.alreadyHave.push(slice);
        }
        this.updateBuffer(data);
      } else {
        console.log('error fetching data')
        const googleUser = localStorage.getItem('rtv-logged-with-google')
        if (googleUser && googleUser === 'true') {
          this.$router.push("/alt");
        } else {
          this.$router.push("/");
        }
      }          
    },

    sliceName(date) {
      return date.format("[D]YYYYMMDD[T]HHmm");
    },

    async stream() {
      // on first load, we load some earlier data points, so that the map does not start empty

      const slice = moment()
        .utc()
        .subtract(this.minuteShift + this.firstLoadPrefetchMinutes, "minute");
      const sliceSet = [];

      for (
        let i = 0;
        i < this.bufferSize + this.firstLoadPrefetchMinutes;
        i++
      ) {
        const sliceStr = this.sliceName(slice);
        if (!this.alreadyHave.includes(sliceStr)) sliceSet.push(sliceStr);
        slice.add(1, "minute");
      }
      if (sliceSet.length > 0) {
        await this.pullData(this.streams, sliceSet);
      }

      this.firstLoadPrefetchMinutes = 0; // we only do this on first load
    },
    receiveData() {
      const self = this;
      this.intervalid1 = setInterval(this.stream, 1000);
    },
  },
};
</script>

