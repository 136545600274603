import axios from 'axios'
import appSettings from '@/configs/appSettings.js'
import { getJWTToken } from '@/services/auth0Plugin'
import store from '@/store/index.js';

const google_token_refresh_threshold = 500
const API_URL = process.env.VUE_APP_BACKEND_API_URL

class ApiService {
  constructor() {
    this.axiosInstance = axios.create({
      baseURL: appSettings.backendApiURL,
      headers: appSettings.axiosHeaders
    })
  }

  async axiosCall(config) {
    try {
      var token = ""
      if (store.state.loginMode && store.state.loginMode.loggedInWithGoogle) {
        var ts = Math.round((new Date()).getTime() / 1000)
        var token_valid_for = store.state.loginMode.details.exp - ts
        if (token_valid_for < google_token_refresh_threshold) {
          console.log('google access token needs refreshing')
          
          const req = {
            user: store.state.loginMode.details.user,
            refresh_token: store.state.loginMode.details.refresh
          }            
          axios.post(`${API_URL}/rtvapi/v1/refresh`, req, {})
            .then(res => {
              // console.log('refreshed the token')
              res.data.loggedInWithGoogle = true
              store.commit("changeLoginMode", res.data);
            })
            .catch(error => {
              console.log('unable to further extend the session, new log-in required')
              return [error]
            });          
        } else {
          // console.log('using google token, valid for', token_valid_for, 's')
        }
        // is it still valid? 
        token = store.state.loginMode.details.credentials
      } else {
        token = await getJWTToken()
      }
      config.headers = { Authorization: `Bearer ${token}` }
      const { data } = await this.axiosInstance.request(config)
      return [null, data]
    } catch (error) {
      console.log("error while fetching data", error)
      return [error]
    }
  }

  async get(url, params) {
    return this.axiosCall({ method: 'get', url, params })
  }

  async post(url, params, data) {
    return this.axiosCall({ method: 'post', url, params, data })
  }

  async delete(url, params) {
    return this.axiosCall({ method: 'delete', url, params })
  }

  async put(url, params, data) {
    return this.axiosCall({ method: 'put', url, params, data })
  }
}

export const api = new ApiService()
