<template>&nbsp;</template>

<script>
export default {
  name: "ViewEditRulesView",
  data() {
    return {
      center: { lat: 51.093048, lng: 6.84212 },
      markers: [
        {
          position: {
            lat: 51.093048,
            lng: 6.84212,
          },
        },
      ],
    };
  },
  components: {},
};
</script>
