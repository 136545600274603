import { createApp } from 'vue'
import Shell from './Shell.vue'
import router from './router'
import store from './store'
import VueAxios from 'vue-axios'
import axios from 'axios'
import './styles/index.css'
import VueGoogleMaps from '@fawmi/vue-google-maps'

createApp(Shell).use(store).use(router).use(VueAxios, axios).use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyA3Gzj3kzhYMcKcz0NIt7fGDzAYUuw1gHw',
    libraries: 'visualization'
  }
}).mount('#app')
