<template>
  <div v-if="auth0 && auth0.isLoading.value" class="page-layout">
    <Preloader :type="'full'" />
  </div>
  <template v-else>
    <div v-if="isLoginPage">
      <router-view />
    </div>
    <div v-else>
      <div class="h-screen w-screen relative">
        <GMapMap
          :center="center"
          :zoom="7"
          @click="onClick"
          :options="{
            zoomControl: true,
            mapTypeControl: false,
            mapId: '1bb72ea7f3fc6c54',
            streetViewControl: false,
            rotateControl: false,
            fullscreenControl: false,
            disableDefaultUi: false,
            zoomControlOptions: 'position: TOP_CENTER',
          }"
          class="h-screen w-screen"
        >
          <GMapHeatmap
            :data="heatData"
            :options="{
              radius: 10,
              opacity: 1,
              gradient: gradient1,
            }"
          ></GMapHeatmap>
          <GMapHeatmap
            :data="heatData2"
            :options="{
              radius: 7,
              opacity: 0.8,
              gradient: gradient2,
            }"
          ></GMapHeatmap>
        </GMapMap>
        <DataFetcher :auth0="auth0" @new-data="newData" />
        <Feed :recentApplies="recentApplies" />
        <Info />
        <div class="title">
          <img src="/randstad4.svg" class="small-img" />
        </div>
      </div>
    </div>
  </template>
</template>

<script>
import Preloader from "@/components/common/ui/Preloader.vue";
import Feed from "@/components/Feed.vue";
import Info from "@/components/Info.vue";
import appSettings from "@/configs/appSettings.js";
import { useAuth0 } from "@/services/auth0Plugin";
import { onMounted, ref, watch } from "vue";
import { GMapApi } from "@fawmi/vue-google-maps";
import DataFetcher from "@/components/data/DataFetcher.vue";
import moment from "moment";

// grey:            <!-- mapId: '302f04a71daf9b5d', -->

const randstadWhite = "rgba(247, 245, 240, 1)";
const randstadWhiteA = "rgba(247, 245, 240, 0)";
const randstadYellow = "rgba(255, 181, 17, 1)";
const randstadYellowA = "rgba(255, 181, 17, 0)";
const randstadBlue = "rgba(33, 117, 217, 1)";
const randstadBlueA = "rgba(33, 117, 217, 0)";
const randstadRed = "rgba(231, 69, 54, 1)";
const randstadRedA = "rgba(231, 69, 54, 0)";
const randstadTeal = "rgba(107, 165, 180, 1)";
const randstadTealA = "rgba(107, 165, 180, 0)";

const jobsColor = randstadWhite;
const jobsColorA = randstadWhiteA;

const candidatesColor = randstadYellow;
const candidatesColorA = randstadYellowA;

export default {
  components: {
    Preloader,
    DataFetcher,
    Feed,
    Info,
  },
  computed: {
    isLoginPage() {
      return this.$route.path === "/" || this.$route.path === "/alt";
    },
    buttonThemeModeText() {
      return this.themeDarkMode ? "Light mode" : "Dark mode";
    },
  },
  data() {
    return {
      center: { lat: 51.093048, lng: 6.84212 },
      refreshCycleMs: 1000,
      buffer: {},
      heatData: [],
      heatData2: [],
      recentApplies: [],
      gradient2: [
        candidatesColorA,
        candidatesColor,
        candidatesColor,
        candidatesColor,
        candidatesColor,
        candidatesColor,
        candidatesColor,
        candidatesColor,
        candidatesColor,
        candidatesColor,
        candidatesColor,
        candidatesColor,
        candidatesColor,
        candidatesColor,
        candidatesColor,
        candidatesColor,
        candidatesColor,
        candidatesColor,
        candidatesColor,
      ],
      gradient1: [
        jobsColorA,
        jobsColor,
        jobsColor,
        jobsColor,
        jobsColor,
        jobsColor,
        jobsColor,
        jobsColor,
        jobsColor,
        jobsColor,
        jobsColor,
        jobsColor,
        jobsColor,
        jobsColor,
        jobsColor,
        jobsColor,
      ],
      intervalid1: null,
      markers: [
        {
          position: {
            lat: 51.093048,
            lng: 6.84212,
          },
        },
      ],
    };
  },
  setup() {
    let auth0 = false;
    const myMapRef = ref();

    watch(myMapRef, (googleMap) => {
      if (googleMap) {
        googleMap.$mapPromise.then((map) => {
          console.log(window.google);
        });
      }
    });

    if (appSettings.auth0Active === "true") {
      auth0 = useAuth0();
      onMounted(async () => {
        if (!auth0) {
          return;
        }
        await auth0.createClient();
        await auth0.handleCallback();
      });
    }

    return {
      auth0,
    };
  },
  mounted: function () {
    this.intervalid1 = setInterval(this.refreshHeatmaps, this.refreshCycleMs);
  },
  beforeUnmount() {
    clearInterval(this.intervalid1);
  },

  methods: {
    newData(buffer) {
      for (const key in buffer) {
        this.buffer[key] = buffer[key];
      }
    },
    describeApplication(event) {
      const jobDescription = event.jobDescription
        ? event.jobDescription
        : "a job";
      const city = event.jobCity ? ` in ${event.jobCity}` : "";
      const candidateCountry = event.personCountry
        ? ` from ${event.personCountry}`
        : "";

      const chat = event.person ? " and is pre-screening" : "";

      if (event.jobCountry === event.personCountry) {
        return `A candidate ${candidateCountry} applied for ${event.jobDescription}${city}${chat}`;
      } else {
        return `A candidate ${candidateCountry} applied for ${event.jobDescription}${city}, ${event.jobCountry}${chat}`;
      }
    },
    refreshHeatmaps() {
      // prepare the points array, but only refresh the component if something new actually got into the time window
      const points1 = [];
      const points2 = [];

      const now = moment();
      let addedNewPoints = false;
      let appliesLog = this.recentApplies;

      for (const key in this.buffer) {
        const event = this.buffer[key];

        if (now.isAfter(event.ts) && now.isBefore(event.ttl)) {
          if (!event.added) {
            addedNewPoints = true;
            const desc = this.describeApplication(event);

            if (!appliesLog.includes(desc)) {
              appliesLog.push(desc);
            } else {
              // console.log("Duplicate event", key, this.buffer);
            }

            if (appliesLog.length > 5) {
              appliesLog = appliesLog.slice(
                appliesLog.length - 5,
                appliesLog.length
              );
            }
          }

          event.added = true;

          if (event.job) {
            points1.push({
              location: new window.google.maps.LatLng({
                lat: event.job[0],
                lng: event.job[1],
              }),
            });
          }

          if (event.person) {
            points2.push({
              location: new window.google.maps.LatLng({
                lat: event.person[0],
                lng: event.person[1],
              }),
            });
          }
        }
      }

      if (addedNewPoints) {
        this.recentApplies = appliesLog;

        if (points1.length > 1) this.heatData = points1;
        if (points2.length > 1) this.heatData2 = points2;
        console.log("Refreshed");
      }
    },
    onClick() {},
    changeThemeMode() {
      this.themeDarkMode = !this.themeDarkMode;
      document.body.classList.toggle("dark-mode");
      this.$store.commit("changeThemeDarkMode", this.themeDarkMode);
    },
  },
};

/*

- adding 3 (configurable) lines with the latest apply details 
- change the colour of profiles from RCE

Remco:

- svg with the logo 
- gradients for jobs and profiles 

*/
</script>


<style scoped>
.fullscreen {
  position: relative;
}

.title {
  display: block;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.small-img {
  height: 5rem;
  left: 2rem;
  top: 2rem;
  position: absolute;
  pointer-events: none;
}
</style>
