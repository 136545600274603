import { isAuthenticated, isLoading } from '@/services/auth0Plugin'
import { watch } from 'vue'
import appSettings from '@/configs/appSettings.js'
import store from '@/store/index.js';

export const authenticationGuard = (to, from, next) => {
  // console.log('Using auth guard', to, from, next)
  if (appSettings.auth0Active === 'true') {
    const guardAction = () => {
      if (isAuthenticated.value || store.state.loginMode.loggedInWithGoogle) {
        return to.name === 'Login' ? next({ name: 'Live' }) : next()
      }

      const prefersGoogle = localStorage.getItem("rtv-logged-with-google")
      if (prefersGoogle && prefersGoogle === "true") {
        console.log("Redirecting to google login (preferred)")
        return to.name === 'LoginAlt' ? next() : next({ name: 'LoginAlt' })
      } else {
        console.log("Redirecting to auth0 login (preferred)")
        return to.name === 'Login' ? next() : next({ name: 'Login' })
      }
    }

    if (!isLoading.value) {
      return guardAction()
    }

    watch(isLoading, (currentValue) => {
      if (!currentValue) {
        return guardAction()
      }
    })
  } else {
    return next()
  }
}
