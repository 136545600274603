import { createRouter, createWebHistory } from 'vue-router'
import Login from '@/views/Login.vue'
import LoginAlt from '@/views/LoginAlt.vue'
import Live from '@/views/Live.vue'
import PathNotFound from '@/views/PathNotFound.vue'
import { authenticationGuard } from '@/services/authenticationGuard'

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login,
    beforeEnter: authenticationGuard
  },
  {
    path: '/alt',
    name: 'LoginAlt',
    component: LoginAlt
  },
  {
    path: '/live',
    name: 'Live',
    component: Live,
    beforeEnter: authenticationGuard
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
