<template>
  <div class="info-img">
    <img @click="expandMe()" src="Help_Icon_Blue_RGB.svg" />
  </div>
  <div v-if="expanded" class="info-box" @click="expandMe()">
    <h1>Welcome to randstad live!</h1>
    <p>
      This application is meant to show near real time activity within our
      company. It allows you to see how often candidates apply for jobs through
      our websites, it also allows you to see where our chatbot is in action to
      prescreen candidates that applied for positions. Over time we expect more
      signals to start appearing, giving you a real live feeling of the
      magnitude of a company of our size! The purpose of this application is
      therefore simple: it gives employees a better understanding of the amount
      of activity that is happening in our company 24x7 using a world map as the
      basis.
    </p>
    <h1>How does this application work?</h1>
    <p>
      Every time a candidate presses the apply button on our websites, a lot of
      things are triggered in the form of “events”. This “application event”
      informs our ATS systems about the new application, it informs our Google
      Analytics platform about this event and many other systems are informed
      too. Randstad live also receives the event and puts a dot on a world map,
      showing you the location of the job that was applied for. After about 10
      minutes the dot disappears again, so the map shows you typically the
      activity within randstad over the last 10 minutes. Our corporate Randstad
      Chatbot RCE also creates events when chats start and when they finish, and
      these events can also be shown on a map. You can zoom in and out of the
      map down to street level as you wish.
    </p>
    <h1>Why are not all randstad countries showing activity?</h1>
    <p>
      Randstad live is built on top of our global Relevate Experience Platform
      (RXP), formerly known as BlueX. As not all countries are yet on RXP, not
      all countries will show their data yet. We expect more countries to start
      appearing over the next months.
    </p>

    <h1>Why are all jobs showing up in one location for a country?</h1>
    <p>
      Randstad live relies on location data to be able to show the dot in the
      right place on the map. Not all countries/systems can yet provide location
      data, and for those countries most applications will show up at the
      randstad HQ location. For applications that have no client data (ie,
      filling up talent pools for some high demand talent profiles) the
      application will show up at the local randstad HQ location as well.
    </p>
    <h1>How accurate is the location of the dot?</h1>
    <p>
      This depends greatly on the quality of the job data. If we have “rooftop
      address data” you can expect the dot to appear on the right spot on the
      map. For less detailed job data the dot might be placed close to the job
      location but not in the exact spot. Randstad live can not be used to
      navigate to client locations or for other location specific purposes.
    </p>
    <h1>Does Randstad live use personal data?</h1>
    <p>
      No. The application only uses the location and job title of the job and
      the timestamp of the application. No candidate data is used. The
      application also does not store any historical data. We will continue to
      monitor the application and its usage to ensure that our data privacy and
      security policies are always followed.
    </p>
    <h1>How can I add my application to Randstad live?</h1>
    <p>
      Yes, if you have a system with event based, real time data that contains
      lat/lon position data then your application could be added to Randstad
      Live too. Get in touch with us.
    </p>
    <h1>How to contact us</h1>
    <p>
      Randstad live is a simple application built by the Data CoE in the digital
      factory. If you want more information then please contact
      lukasz.rewerenda@randstad.com or remco.brouwer@randstad.com.
    </p>
  </div>
</template>

<script>
export default {
  name: "Info",
  data() {
    return {
      expanded: false,
    };
  },
  methods: {
    expandMe() {
      this.expanded = !this.expanded;
    },
  },
};
</script>

<style>
.info-img {
  position: absolute;
  top: 0rem;
  right: 0rem;
  width: 5rem;
}

.blue {
  color: rgba(33, 117, 217, 1);
}

.yellow {
  color: rgba(255, 181, 17, 1);
}

.info-box {
  position: absolute;
  left: 5%;
  top: 10%;
  width: 90%;
  height: 70%;
  overflow-y: scroll;

  background-color: rgba(0, 0, 0, 0.6);
  padding: 20px;
  border-radius: 1rem;
}

.info-box p {
  color: rgba(247, 245, 240, 1);
  font-weight: 600;
  font-size: 1rem;
  margin: 1rem;
  line-height: initial;
}

.info-box h1 {
  color: rgba(247, 245, 240, 1);
  font-weight: 600;
  margin: 1rem;
  margin-top: 2rem;
  font-size: 2rem;
}

@media only screen and (max-width: 800px) {
  .info-box {
    top: 15%;
    height: 50%;
  }
  .info-box p {
    font-size: 1rem;
    margin: 0.2rem;
  }

  .info-box h1 {
    margin: 0.2rem;
    font-size: 1.5rem;
  }
}
</style>