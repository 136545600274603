import axios from 'axios';
import { ref } from 'vue';

export default function() {
  const CLIENT_ID = process.env.VUE_APP_GOOGLE_CLIENT_ID
  const CLIENT_URL = process.env.VUE_APP_CLIENT_URL
  const API_URL = process.env.VUE_APP_BACKEND_API_URL

  const userData = ref()

  const googleOptions = {
    client_id: CLIENT_ID, // required
    auto_select: true, // optional
    cancel_on_tap_outside: true, // optional
    context: 'signin', // optional
  };

  const googleOneTap = ({ client_id, auto_select = false, cancel_on_tap_outside = false, context = 'signin' }, callback) => {
    const contextValue = ['signin', 'signup', 'use'].includes(context) ? context : 'signin';
    const googleScript = document.createElement('script');
    googleScript.setAttribute('src', 'https://accounts.google.com/gsi/client');
    document.head.appendChild(googleScript)
    // googleScript.onload instead of window.onload because window.onload can be triggered by other libraries and or just missed while googleScript.onload is more consistent
    googleScript.onload = () => {
      if (client_id) {
        window.google.accounts.id.initialize({
          client_id: client_id,
          callback: callback,
          auto_select: auto_select,
          cancel_on_tap_outside: cancel_on_tap_outside,
          context: contextValue
        });
        window.google.accounts.id.prompt();
      } else {
        console.error('client_id is missing');
      }
    };
  }

  function parseJwt(token) {
    var base64Payload = token.split('.')[1];
    var payload = Buffer.from(base64Payload, 'base64');
    return JSON.parse(payload.toString());
  }

  const oneTapSignin = (options) => {
    googleOneTap(options, (res) => {
      // Send response to server
      let user_id = "unknown"
      if (res && res.credential) {
        const jwt = parseJwt(res.credential)
        if (jwt) {
          user_id = jwt.email
        }
      }

      // Access-Control-Allow-Origin is mandatory as this is a "Complex" request. This must match the "origin" in the CorsOptions in the backend, or it will fail the preflight.
      const axiosOptions = {
        headers: { 
          Authorization: "Bearer " + res.credential
        }
      }

      // Google One-Tap Signin sends a POST request which must be sent to a server to be processed.
      axios.post(`${API_URL}/rtvapi/v1/login`, res, axiosOptions)
        .then(res => {
          // Continue Auth Flow with data from res.data
          const user = res.data.user
          const refreshToken = res.data.refresh
          const exp = res.data.exp
          const token = res.data.credentials

          res.data.loggedIn = true
          userData.value = res.data
        })
        .catch(error => {
          userData.value = { errorMsg: error, user: user_id }
        });
    });
  }

  return { googleOptions, oneTapSignin, userData }
}