<template>
  <div class="log">
    <div v-for="apply in recentApplies" :key="apply" class="log-entry">
      {{ apply }}
    </div>
  </div>
</template>

<script>
export default {
  name: "Feed",
  props: {
    recentApplies: Array,
  },
};
</script>

<style>
.log {
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.1);
  padding: 20px;
  pointer-events: none;
  border-radius: 1rem;
}

.log-entry {
  color: rgba(247, 245, 240, 1);

  font-weight: 600;
  padding: 0.1rem;
}
</style>