<template>
  <div
    class="login-page h-screen flex items-center justify-center p-4 bg-cover"
  >
    <div
      class="
        flex flex-col
        items-center
        w-full
        max-w-sm
        px-8
        box-border
        bg-white
        border
        shadow-2xl
        rounded-md
        pb-10
      "
    >
      <img class="h-16 mt-8 my-4" src="../assets/icons/icon-lock.svg" />
      <h1 class="text-2xl mb-6">Randstad Live</h1>
      <div v-if="!googleUserData.loggedIn && !googleUserData.errorMsg">
        <p>Logging you in with Google</p>
      </div>
      <div v-if="googleUserData.loggedIn">
        <p>Welcome!</p>
      </div>
      <div v-if="googleUserData.errorMsg" class="error-container">
        <p class="text-xl error">Access denied.</p>
        <p class="text-xl">User account <b>{{googleUserData.user}}</b> not authorized to log in with google</p>
        <br>
        <a href="/">Click here to log in with a Randstad account</a>
      </div>
    </div>
  </div>
</template>

<script>
import googleOneTapSignin from '../services/googleAuth' 
import { onMounted, ref, watch } from 'vue'

export default {
  name: 'HelloWorld',
  props: {
    msg: String
  },
  watch: {
    googleUserData () {
      if (this.googleUserData.loggedIn) {
        console.log("Setting user login preference as google")
        localStorage.setItem('rtv-logged-with-google', "true")
        this.$store.commit("changeLoginMode", this.googleUserData);
        this.$router.push("/");
      } else {
        localStorage.removeItem('rtv-logged-with-google')
        console.log("User log in preference changed to auth0")
      }
    }
  },
  setup () {
    const googleUserData = ref({
      name: '',
      email: '',
      email_verified: '',
      picture: '',
      loggedIn: false 
    })
    
    onMounted(() => {
      const { googleOptions, oneTapSignin, userData } = googleOneTapSignin()

      oneTapSignin(googleOptions)

      watch(userData, () => {
        googleUserData.value = userData.value
      })
    })

    return { googleUserData }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.error {
  font-weight: 600;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.error-container {
  text-align: center;
  background: rgb(247, 192, 192);
  padding: 0.2rem;
}
a {
  color: black;
}

</style>